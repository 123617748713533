import { Order } from '@/components/pages/pagination-layout'
import { IScanReport } from '@/interface/scan-report.interface'
import { IReports, IScans } from '@/interface/scan.interface'
import httpService from '@/services/httpService'

export const useScanService = () => {
  const getScans = async (
    statuses: string,
    page: number,
    rowsPerPage: number,
    order: Order,
    search: string,
    orderBy?: string,
  ): Promise<IScans> => {
    const res: IScans = await httpService.get(
      `/v1/admin/scans?page=${page}&limit=${rowsPerPage}&${statuses}&order=${order}&orderBy=${orderBy}&search=${search}`,
    )
    return Promise.resolve(res)
  }
  const getReports = async (scanId: number): Promise<IScanReport[]> => {
    const res: IReports = await httpService.get(`/v1/admin/scans/${scanId}/reports`)
    return Promise.resolve(res.data)
  }

  const updateScanStatus = async (scanId: number, status: string): Promise<any> => {
    const res = await httpService.patch(`/v1/admin/scans/${scanId}`, {
      status: status,
    })
    return Promise.resolve(res)
  }

  const updateSelfScanStatus = async (scanId: number, status: string): Promise<any> => {
    const res = await httpService.patch(`/v1/self/scans/${scanId}`, {
      status: status,
    })
    return Promise.resolve(res)
  }

  const updateReportStatus = async (reportId: number, status: string): Promise<any> => {
    const res = await httpService.patch(`/v1/admin/scans/report/${reportId}`, {
      status: status,
    })
    return Promise.resolve(res)
  }

  const getSelfScans = async (statuses: string): Promise<IScans> => {
    const res: IScans = await httpService.get(`/v1/self/scans?page=0&limit=500&${statuses}`)
    return Promise.resolve(res)
  }
  const getSelfReports = async (scanId: number): Promise<IScanReport[]> => {
    const res: IReports = await httpService.get(`/v1/self/scans/${scanId}/reports`)
    return Promise.resolve(res.data)
  }

  return {
    getScans,
    getReports,
    updateReportStatus,
    updateScanStatus,
    getSelfScans,
    updateSelfScanStatus,
    getSelfReports,
  }
}
