import { IForgotPasswordResponse } from '@/interface/forgot-password-response.interface'
import { IRestPasswordResponse } from '@/interface/rest-password-response.interface'
import { IAddress, IUser } from '@/interface/user.interface'
import httpService from '@/services/httpService'
import { logger } from '@/services/index'
import { useRouter } from 'next/router'
import useSWR from 'swr'

export const useAuthService = () => {
  const router = useRouter()

  const {
    data: user,
    mutate,
    error,
  } = useSWR('/auth/user', (url) => {
    const token = localStorage.getItem('token')
    if (!token) {
      console.debug('token exist!')
      return
    } else
      return httpService
        .get(url)
        .then(async (res: any) => {
          return res.data
        })
        .catch(async (error) => {
          if (error.response.status === 401) {
            await signOut()
          } else {
            logger.log(error)
          }
          throw error
        })
  })

  const signIn = async (username: string, password: string): Promise<boolean> => {
    const res: { data: { token: string; refreshToken: string } } = await httpService.post('auth/login', {
      username,
      password,
      type: 'admin',
    })
    localStorage.setItem('token', res.data.token)
    await mutate('/auth/user')
    return Promise.resolve(true)
  }

  const signOut = async () => {
    localStorage.removeItem('token')
    await mutate({})
    await router.replace('/')
  }

  const forgotPassword = async (username: string): Promise<IForgotPasswordResponse> => {
    const res: IForgotPasswordResponse = await httpService.post('auth/forgot-password', {
      username,
      portalType: 'admin',
    })
    return Promise.resolve(res)
  }

  const resetPassword = async (newPassword: string, token: any): Promise<IRestPasswordResponse> => {
    const res: IRestPasswordResponse = await httpService.post('auth/reset-password', {
      newPassword,
      type: 'email',
      portalType: 'admin',
      token,
    })
    return Promise.resolve(res)
  }

  const updateProfile = async (user: IUser): Promise<boolean> => {
    await httpService.put('/v1/user', {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: Number(user.mobile),
    })
    return Promise.resolve(true)
  }

  const addAddress = async (address: IAddress): Promise<boolean> => {
    await httpService.post('/v1/user/address', {
      zipCode: address.zipCode,
      cityId: address.city?.id,
      street1: address.street1,
      street2: address.street2,
      googleMapLink: address.googleMapLink,
    })
    return Promise.resolve(true)
  }

  const updateAddress = async (address: IAddress, addressId: number): Promise<boolean> => {
    await httpService.put(`/v1/user/address/${addressId}`, {
      zipCode: address.zipCode,
      cityId: address.city?.id,
      street1: address.street1,
      street2: address.street2,
      googleMapLink: address.googleMapLink,
    })
    return Promise.resolve(true)
  }

  const updatePassword = async (
    oldPassword: string,
    newPassword: string,
  ): Promise<{ success: boolean; message: string }> => {
    const res: { success: boolean; message: string } = await httpService.put('/auth/password', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    })
    return Promise.resolve(res)
  }

  const getAddresses = async (): Promise<IAddress[]> => {
    const res: { data: IAddress[] } = await httpService.get('/v1/user/address')
    return Promise.resolve(res.data)
  }

  return {
    signIn,
    forgotPassword,
    resetPassword,
    signOut,
    getAddresses,
    updateProfile,
    addAddress,
    updateAddress,
    updatePassword,
    user,
    error,
  }
}
