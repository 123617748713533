/**
 * Global error handler!
 * Here to handle the amazing variety of errors we can get.
 */
import { IDiagnosticCenter, IScanner, IStaff } from '@/interface/diagnostic-center.interface'
import { IPaginationRequest } from '@/interface/pagination.interface'
import { IAddress, IUser } from '@/interface/user.interface'
import { logger } from '@/services'
import jwtDecode from 'jwt-decode'

interface AxiosResponse {
  data: {
    code: number
    message: string
  }
}

interface AxiosErrorResponse {
  status: number
  data: Error
}

interface Error {
  statusCode: string
  message: string
}

function instanceOfAxiosResponse(object: any): object is AxiosResponse {
  return (
    'data' in object &&
    'code' in object.data &&
    'message' in object.data &&
    typeof object.data.code === 'number' &&
    typeof object.data.message === 'string'
  )
}

function getMessages(message: string | string[]): string {
  if (Array.isArray(message)) {
    return message.join(', ')
  }
  return message
}

export const handleError = (
  error: any,
  callback: (message: string, code: string | number) => void,
  message = 'General error',
) => {
  if (error['response'] && instanceOfAxiosResponse(error['response'])) {
    const response = error['response'] as AxiosResponse
    return callback(response.data.message, response.data.code)
  }

  if (error instanceof Error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = error['response'] as AxiosErrorResponse
    if (response) {
      return callback(getMessages(response.data.message), response.status)
    }
    logger.log(error)
    const errorCode = 'code' in error ? error['code'] : '0'

    return callback(error.message ? getMessages(error.message) : message, Number(errorCode))
  }

  if (typeof error === 'object' && error !== null) {
    return callback(
      typeof error['message'] === 'string' ? error['message'] : message,
      typeof error['code'] !== 'undefined' ? error['code'] : 'ErrorObject',
    )
  }

  if (typeof error === 'string') {
    if (error.substring(0, 1) === '{') {
      try {
        const parsed = JSON.parse(error)
        return callback(
          typeof parsed['message'] === 'string' ? parsed['message'] : message,
          typeof parsed['code'] === 'string' || typeof parsed['code'] === 'number'
            ? parsed['code']
            : 'ErrorStringObject',
        )
      } catch (error) {
        return callback(message, 'ErrorJsonParse')
      }
    } else return callback(error, 'ErrorString')
  }

  return callback(message, 'GeneralError')
}

/**
 * Get random avatar color and first letter from string.
 */
export const stringToColor = (input?: string) => {
  let hash = 0
  input = input ?? ''
  for (let i = 0; i < input.length; i++) {
    hash = input.charCodeAt(i) + ((hash << 5) - hash)
  }
  let color = '#'
  let value: number
  for (let i = 0; i < 3; i++) {
    value = (hash >> (i * 8)) & 0xff
    const valueString = '00' + value.toString(16)
    color += valueString.substring(valueString.length - 2)
  }
  return color
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getUserFullName = (user?: IUser) => {
  return `${capitalizeFirstLetter(user?.firstName || 'no')} ${capitalizeFirstLetter(user?.lastName || 'name')}`
}

export const getUserLastFirstName = (user?: IUser) => {
  return `${capitalizeFirstLetter(user?.lastName || '-')}, ${capitalizeFirstLetter(user?.firstName || '-')}`
}

export const getUserRole = (user: IUser) => {
  return user.roles.length > 0 ? user.roles[0].displayName : '-'
}

export function getMockAddress(): IAddress {
  return {
    city: undefined,
    googleMapLink: '',
    id: 0,
    street1: '',
    street2: '',
    zipCode: '',
  }
}

export function getMockUser(): IUser {
  return {
    emailVerified: false,
    exp: 0,
    gender: '',
    id: 0,
    mobileVerified: false,
    permissions: [],
    roles: [],
    signedIn: false,
    status: '',
    addresses: [getMockAddress()],
    avatar: '',
    email: '',
    firstName: '',
    lastName: '',
    mobile: '',
  }
}

export function getMockDiagnosticCenterStaff(): IStaff {
  return {
    email: '',
    role: '',
  }
}

export function getMockDiagnosticCenter(): IDiagnosticCenter {
  return {
    locations: [],
    id: 0,
    institutionName: '',
    invoicingBusinessEmail: '',
    invoicingBusinessName: '',
    invoicing_tax_id: undefined,
    logo: undefined,
    numberOfEmployees: '',
    providerType: '',
    scanPerDay: '0',
    numberOfScanners: 1,
    numberOfLocations: 1,
    website: '',
    address: getMockAddress(),
    scanners: [],
    owner: getMockUser(),
    staff: [getMockDiagnosticCenterStaff()],
  }
}

export function getMockScannerServices() {
  return {
    calcium_score: {
      label: 'Calcium Score',
      checked: false,
    },
    lung_nodule: {
      label: 'lung_nodule',
      checked: false,
    },
    bone_density: {
      label: 'Bone density',
      checked: false,
    },
    fatty_liver: {
      label: 'Fatty liver',
      checked: false,
    },
  }
}

export const availableServices = [
  {
    name: 'calcium_score',
    title: 'Calcium Score',
  },
  {
    name: 'bone_density',
    title: 'Bone density',
  },
  {
    name: 'fatty_liver',
    title: 'Fatty liver',
  },
  {
    name: 'lung_nodule',
    title: 'Lung nodule',
  },
]

export function getMockScanner(): IScanner {
  return {
    contracts: [],
    id: 0,
    availableServices: [],
    name: '',
    contract: 0,
    manufacturer: '',
    modelNo: '',
    slice: '',
    serialNumber: '',
    yearManufactured: '',
    services: getMockScannerServices(),
  }
}

export const getDefaultUserAddress = (addresses?: IAddress[]): IAddress => {
  if (!addresses) return getMockAddress()
  return addresses.length > 0 ? addresses[0] : getMockAddress()
}

export const hasRole = (user: IUser, role: string): boolean => {
  return user?.roles?.filter((r) => r.name === role).length > 0
}

export const hasPermissions = (permission: string, user?: IUser): boolean => {
  if (!user) {
    try {
      const cUser = getUserByToken(localStorage.getItem('token'))
      if (cUser) return cUser.permissions?.filter((r) => r.name === permission).length > 0
      return false
    } catch (e) {
      return false
    }
  }
  return user.permissions?.filter((r) => r.name === permission).length > 0
}

export const stringAvatar = (user: IUser) => {
  const name = `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
  if (name.length > 0) return name.toUpperCase()
  return 'NU'
}
export const stringAvatarByName = (firstName: string, lastName: string) => {
  const name = `${firstName?.charAt(0)}${lastName?.charAt(0)}`
  if (name.length > 0) return name.toUpperCase()
  return 'NU'
}

export const isExpired = (exp: number): boolean => {
  return new Date().getTime() / 1000 >= exp
}

export const getUserByToken = (token: any): IUser | null => {
  if (!token) return null
  return jwtDecode<IUser>(token)
}

export const validEmail = (email: string): boolean => {
  const regexp = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$')
  return regexp.test(email)
}

export const validMobile = (mobile: string): boolean => {
  const regexp = new RegExp('^[(]?[0-9]{3}[)]?[0-9]{3}[0-9]{4}$')
  return regexp.test(mobile)
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const getOneLineAddress = (address?: IAddress): string => {
  if (!address) return '-'
  if (!address.city?.id) return '-'
  // '18 Bartol St #1336  San Francisco California 94133',
  // eslint-disable-next-line max-len
  return `${address.street2} ${address.street1} ${address.city?.name} ${address.city?.state?.name} ${address.city?.state?.country.name} ${address.zipCode}`
}

export const getPaginationRequest = (req: IPaginationRequest): string => {
  const { order, fields, filter, or, sort, join, s, page, limit, offset, cache } = req
  const _filter = filter.length === 0 ? '' : `&${filter.map((value) => `filter=${value}`).join('&')}`
  const _or = or.length === 0 ? '' : `&${or.map((value) => `or=${value}`).join('&')}`
  const _sort = sort.length === 0 ? '' : `&${sort.map((value) => `sort=${value},${order.toUpperCase()}`).join('&')}`
  const _join = join.length === 0 ? '' : `&${join.map((value) => `join=${value}`).join('&')}`
  const _fields = filter.length === 0 ? '' : `fields=${fields.toString()}`
  const _s = s.length === 0 ? '' : `&s=${s}`
  // eslint-disable-next-line max-len
  return `cache=${cache || 0}${_fields}${_s}${_filter}${_or}${_sort}${_join}&limit=${limit || 10}&offset=${
    offset || 0
  }&page=${page || 1}`
}
