// import * as React from 'react'
import { AuthContextProvider } from '@/context/auth-context'
import { ConfigContextProvider } from '@/context/config-context'
import { useAuthService } from '@/services/auth.service'
import { useConfigService } from '@/services/config.service'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
// Import the styles provided by the react-pdf-viewer packages
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { AppProps } from 'next/app'
import Head from 'next/head'
import theme from '../components/layout/theme'
import createEmotionCache from '../createEmotionCache'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  const authService = useAuthService()
  const configService = useConfigService()
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Livelong panel</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ConfigContextProvider value={configService}>
          <AuthContextProvider value={authService}>
            <Component {...pageProps} />
          </AuthContextProvider>
        </ConfigContextProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}
