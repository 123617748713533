import { IPaginationRequest } from '@/interface/pagination.interface'
import { ISuccessPaginationResponse } from '@/interface/success-pagination-response.interface'
import { ISuccessResponse } from '@/interface/success-response.interface'
import { IAddress, IUser, IUserActivity } from '@/interface/user.interface'
import { getPaginationRequest } from '@/libraries/util'
import httpService from '@/services/httpService'

export interface DiagnosticCenterAdminData {
  email: string
  mobile: number
  firstName: string
  lastName: string
  verificationType: string
  address?: IAddress
}

export const useInternalUserService = () => {
  const getUsers = async (request: IPaginationRequest): Promise<ISuccessPaginationResponse> => {
    const res: ISuccessPaginationResponse = await httpService.get(
      `/v2/admin/internal-user?${getPaginationRequest(request)}`,
    )
    return Promise.resolve(res)
  }

  const getUser = async (id: number): Promise<IUser | null> => {
    const res: ISuccessResponse = await httpService.get(`/v1/admin/internal-user/${id}`)
    if (res.success) return Promise.resolve(res.data[0])
    else return Promise.resolve(null)
  }

  const getUserActivities = async (id: number): Promise<IUserActivity[]> => {
    const res: { data: IUserActivity[] } = await httpService.get(`/v1/admin/internal-user/${id}/activities`)
    return Promise.resolve(res.data)
  }

  const updateRole = async (userId: number, roleId: number, status: string): Promise<boolean> => {
    await httpService.put('/v1/admin/internal-user', {
      id: userId,
      roleId: roleId,
      status: status,
      permissionIds: [],
    })
    return Promise.resolve(true)
  }

  const addUser = async (
    users: { email: string; roleId: number; permissionIds: number[] }[],
  ): Promise<ISuccessResponse> => {
    const res: ISuccessResponse = await httpService.post('/v1/admin/internal-user', { users: users })
    return Promise.resolve(res)
  }

  const addDiagnosticCenterAdmin = async (user: DiagnosticCenterAdminData): Promise<ISuccessResponse> => {
    const res: ISuccessResponse = await httpService.post('/v1/admin/internal-user/diagnostic_center_admin', user)
    return Promise.resolve(res)
  }

  const updateUser = async (
    user: IUser,
    roleId?: number,
    status?: string,
    permissionIds?: number[],
    address?: IAddress,
  ): Promise<ISuccessResponse> => {
    const res: ISuccessResponse = await httpService.patch(`/v1/admin/internal-user/${user.id}`, {
      roleId: roleId,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.mobile,
      status: status,
      permissionIds: permissionIds,
      addressId: address?.id,
      cityId: address?.city?.id,
      zipCode: address?.zipCode,
      street1: address?.street1,
      street2: address?.street2,
    })
    return Promise.resolve(res)
  }

  return {
    getUsers,
    getUser,
    addUser,
    updateRole,
    updateUser,
    addDiagnosticCenterAdmin,
    getUserActivities,
  }
}
