import { red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import React from 'react'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color']
    }
  }

  interface Palette {
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary']
  }

  interface PaletteColor {
    darker?: string
  }

  interface SimplePaletteColorOptions {
    darker?: string
  }

  interface TypographyVariants {
    small: React.CSSProperties
    verysmall: React.CSSProperties
    p1: React.CSSProperties
    body: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    small?: React.CSSProperties
    verysmall?: React.CSSProperties
    p1?: React.CSSProperties
    body?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true
    verysmall: true
    p1: true
    body: true
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#636363' /*D8145F*/,
      900: '#181818',
      500: '#636363',
      300: '#9C9C9C',
    },
    secondary: {
      main: '#9C9C9C' /*7893BC*/,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F1F1F1',
    },
    text: {
      primary: '#636363',
    },
    neutral: {
      main: '#64748B',
      50: '#F1F1F1',
      100: '#E2E8F0',
      // 200: '#E2E8F0',
      300: '#9c9c9c',
      400: '#808080',
      500: '#636363',
      600: '#475569',
      // 700: '#334155',
      // 800: '#181818',
      900: '#181818' /**/,
    },
    /*
      50: '#E7EDF7',
      100: '#D0DBEF',
      200: '#B8C9E7',
      300: '#A0B7DF',
      400: '#89A5D6',
      500: '#7193CE',
      600: '#5981C6',
      700: '#426FBE',
      800: '#3961A7',
      900: '#31538F',*/
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'Work Sans',
      'inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      //styleName: H3 33px Work Sans;
      fontFamily: 'Work Sans',
      fontSize: '33px',
      fontWeight: '700',
      lineHeight: '40px',
      letterSpacing: '-0.04em',
      textAlign: 'left',
    },
    h4: {
      //styleName: H4 28px Work Sans;
      fontFamily: 'Work Sans',
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '34px',
      letterSpacing: '-0.03em',
      textAlign: 'left',
    },
    h6: {
      //styleName: H6 - 19px Bold Work Sans ;
      fontFamily: 'Work Sans',
      fontSize: '19px',
      fontWeight: '500',
      lineHeight: '23px',
      letterSpacing: '-0.04em',
      textAlign: 'left',
    },
    body1: {
      fontWeight: 400,
      fontFamily: 'Open Sans',
      fontSize: 14,
      '@media (min-width:600px)': {},
    },
    small: {
      //styleName: Small 14px SemiBold Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    verysmall: {
      //styleName: Very small 12px Regular Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    p1: {
      //styleName: Paragraph/P1 / Regular;
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    body: {
      //styleName: Body 16px Regular Open Sans;
      fontFamily: 'Open Sans',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },
})

export default theme
