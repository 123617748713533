import { IUser } from '@/interface/user.interface'
import httpService from '@/services/httpService'

export const usePatientService = () => {
  // const { pathname } = useRouter()
  const getPatients = async (): Promise<IUser[]> => {
    const res: { data: IUser[] } = await httpService.get('/v1/admin/patient?page=0&limit=500')
    return Promise.resolve(res.data)
  }

  return {
    getPatients,
  }
}
