import { ISuccessResponse } from '@/interface/success-response.interface'
import { IPermission, IRole } from '@/interface/user.interface'
import httpService from '@/services/httpService'

export interface ICreateRole {
  name: string
  displayName: string
  description: string
  permissions: number[]
}

export const useAdminService = () => {
  const getRoles = async (): Promise<IRole[]> => {
    const res: { data: IRole[] } = await httpService.get('/v1/admin/roles')
    return Promise.resolve(res.data)
  }
  const getPermissions = async (): Promise<IPermission[]> => {
    const res: { data: IPermission[] } = await httpService.get('/v1/admin/permissions')
    return Promise.resolve(res.data)
  }

  const addRole = async (data: ICreateRole): Promise<ISuccessResponse> => {
    const res: ISuccessResponse = await httpService.post('/v1/admin/roles', data)
    return Promise.resolve(res)
  }

  return {
    addRole,
    getRoles,
    getPermissions,
  }
}
